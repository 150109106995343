.registerForm {
  padding-top: 30px;
  display: flex;
  justify-content: center;
  .form {
    width: auto;
    .ant-select,.ant-picker {
      width: 100%!important;
    }
    .ant-form-item-label {
      width: 160px;
    }
    .title {
      border-left: solid #b71c3a 5px;
      font-size: 18px;
      font-weight: 700;
      padding-left: 10px;
    }
    .review {
      margin-top: 25%;
      display: flex;
      .review-title {
        font-size: 18px;
        margin-left: 20px;
        font-weight: 700;
        margin-bottom: 30px;
      }
      .review-content {
        margin-left: 20px;
        margin-bottom: 10px;
        .yellow-text {
          color: rgb(245, 154, 35);
        }
      }
    }
    .review-back {
      margin-top: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
