#home {
  .left-back {
    margin-right: 10px;
    .anticon-left-square {
      display: inline-block;
      height: 40px;
      width: 40px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .container {
     height: 100%;
    display: flex;
    .base-right {
      height: 100vh;
      overflow: auto;
      background-color: #f2f2f8;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      .ant-menu-item {
        border-radius: 0;
        border-right: solid white 5px;
      }
      .ant-menu-item-selected,.ant-menu-item:active,.ant-menu-item:hover {
        color: white;
        border-right: solid rgb(217, 0, 27) 5px;
        background: rgb(228, 74, 93);
        border-radius: 0;
      }
      .sideR-top {
        .menu-logo {
          display: flex;
          align-items: center;
          img {
            margin-right:10px ;
            width: 50px;
          }
        }
        display: flex;
        height: 64px;
        position: sticky;
        top: 0;
        z-index: 99;
        box-sizing: border-box;
        padding: 0 20px 0 20px;
        flex-shrink: 0;
        background-color: #fff;
        align-items: center;
        justify-content: space-between;
        box-shadow:0px 3px 10px 0px #e6e9f0;
        .tit-back {
          display: flex;
          align-items: center;
          .left-back {
            &:hover {
              cursor: pointer;
            }
            img {
              width: 40px;
              height: 40px;
            }
          }
          span {
            font-size: 16px;
            font-weight: 700;
            color: #333333;
            line-height: 24px;
            letter-spacing: 2px;
          }
        }
        .login-out {
          display: flex;
          > div {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #93A0B0;
            img {
              width: 16px;
            }
            span {
              padding-left:4px;
            }
          }
          .sign_out {
            cursor: pointer;
            margin-left: 60px;
            &:hover {
              &.drop {
                cursor: no-drop;
              }
              .sign_out_icon {
                background-image: url("../../../assest/image/sign_out_hover.png");
              }
              span {
                color: #FF4D4F;
              }
            }
            .sign_out_icon {
              width: 16px;
              height: 16px;
              background-image: url("../../../assest/image/sign_out.png");
            }
          }
        }
      }
      .sideR-bottom {
        overflow:auto;
        //margin-top: 20px;
        //margin-left: 20px;
        //margin-right: 20px;
        flex-grow: 1;
        flex-shrink: 0;
        display: flex;
        justify-content: space-between;
        .body-left {
          height: 100%;
          .menu-top {
            height: 6%;
            width: 290px;
            box-sizing: border-box;
            padding: 20px;
            background: #ffffff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
              font-size: 18px;
              font-weight: 700;
            }
          }
          .plate-menu {
            height: 91%;
            width: 290px;
          }
        }
        .body-right {
          padding: 0 15px 25px 15px;
          box-sizing: border-box;
          width: calc(100% - 290px)  ;
          .body-page {
            background: white;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .link-tip {
    // text-align: center;
    height: 40px;
    line-height: 40px;
    font-weight: 700;
    span {
      cursor: pointer;
    }
    span:hover {
      text-decoration: underline;
    }
  }
}
// 锁屏弹窗样式
.lock_dialog_content {
  padding:70px 0 60px;
  background-color: #fff;
  text-align: center;
  border-radius: 2px;
  > div {
    font-size: 16px;
    line-height: 16px;
    color: #333333;
    font-weight: 400;
    letter-spacing: 1px;
    &.account {
      padding: 20px 0 10px;
    }
  }
  img {
    width: 80px;
    height: 80px;
    margin: 0 auto;
  }
  .ant-form {
    width: 300px;
    margin: 24px auto 0;
    font-size: 14px;
    .ant-form-item {
      margin-bottom: 10px;
      .ant-input-password {
        padding: 0 11px;
      }
      input, button {
        line-height: 40px;
        height: 40px;
      }
      &:first-of-type {
        margin-bottom: 30px;
      }
      &.sign_out_btn_form {
        margin-bottom: 0;
      }
    }
    .ant-form-item-explain {
      position: absolute;
      bottom: -25px;
      div {
        text-align: left;
      }
    }
    .ant-btn {
      width: 100%;
      background-color: #3079EA;
      border-color: #3079EA;
      padding: 0;
    }
    .sign_out_btn_form {
      .ant-btn {
        line-height: 16px;
        height: 16px;
        background-color: #fff;
        border: none;
        color: #3079EA;
      }
    }
  }

}
