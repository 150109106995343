#Card {
  height: 600px;
  padding: 20px;
  box-sizing: border-box;
  .ant-table-thead .person-action {
    padding-left: 30px;
  }
  .ant-btn {
    padding: 4px 5px;
  }
  .ant-table-pagination.ant-pagination {
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .ant-table-thead > tr > th {
    font-weight: 700;
  }
  .ant-table-body {
    height: 565px;
  }
  .btn {
    margin-bottom: 20px;
    display: flex;
    justify-content: right;
  }
}
.btnClose {
  display: flex;
  justify-content: center;;
}
.border {
  border: 1px solid rgb(215, 215, 215);
  .title {
    padding: 10px;
    background: rgb(242, 242, 242);
    border-right: 1px solid rgb(215, 215, 215);
    border-bottom: 1px solid rgb(215, 215, 215);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(51, 51, 51);
  }
  .info {
    padding: 10px;
    text-align: center;
    //border-left: 1px solid rgb(215, 215, 215);
    border-bottom: 1px solid rgb(215, 215, 215);
  }
}
.title_card {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  font-size: 17px;
}
.title_card_code {
  display: block;
  //padding: 10px;
  font-size: 35px;
  text-align: center;
}
.border_code {
  width: 170px;
  height: 170px;
  border: 1px solid #1fb19e;
  padding: 8px;
}
.payment {
  display: block;
  width: 100%;
  text-align: center;
  color: #acacac;
  margin-top: 10px;
}
.pay_logo {
  width: 30%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: auto auto 10px;
  img {
    height: 40px;
  }
}
#institutionalTable {
  .ant-table-thead .person-action {
    padding-left: 30px;
  }
  .ant-table-pagination.ant-pagination {
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .ant-table-thead > tr > th {
    font-weight: 700;
  }
  .ant-table-body {
    height: 300px;
  }
  .ant-table-expanded-row-fixed {
    height: 299px;
  }
}
