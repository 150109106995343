#InstitutionalDetails {
  background: rgb(242, 242, 242);
  header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    color: white;
    height: 40px;
    background: rgb(217, 0, 27);
  }
  main {
    margin: auto;
    width: 1200px;
    .cardDetails {
      padding: 20px 25px 30px;
      box-sizing: border-box;
      margin-top: 20px;
      background: white;
      border-radius: 10px;
      .cardDetails_main {
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        .cardDetails_main_card {
          height: 150px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          font-size: 15px;
          .cardDetails_main_info {
            font-weight: 700;
            display: inline-block;
            margin-left: 10px;
          }
          img {
            vertical-align: middle;
            width: 112px;
          }
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .cardDetails_title {
        margin-top: 0;
        margin-bottom: 20px;
        border-left: solid 5px rgb(217, 0, 27);
        padding-left: 10px;
        display: flex;
        align-items: center;
        .abnormal,.normal {
          margin-left: 20px;
          padding: 2px;
          padding-right: 10px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          font-size: 13px;
          font-weight: 400;
          img {
            margin-right: 5px;
            width: 15px;
          }
        }
        .normal {
          color: #d9001b;
          background: #f2aab3;
        }
        .abnormal {
          color: #1fb19e;
          background: #a5e0d8;
        }
      }
      .cardDetails_table {
        height: 500px;
      }
    }
    .cardDetails_image {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 20px;
      .cardDetails_image_left {
        width: 480px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .cardDetails_image_left_box {
          .row {
            width: 100%;
            .ant-col {
              display: flex;
              justify-content: center;
              align-items: center;
              .btn_check {
                cursor: pointer;
                text-decoration: underline;
              }
            }
          }
          padding: 5px 20px;
          border-radius: 7px;
          height: 55px;
          box-sizing: border-box;
          color: white;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      .cardDetails_image_center,.cardDetails_image_right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 275px;
          height: 190px;
          margin-bottom: 10px;
          vertical-align: bottom;
        }
      }
      .cardDetails_image_right {

      }
    }
    .cardDetails_mechanismInfo {
      position: relative;
      display: flex;
      //justify-content: space-around;
      margin-left: 20px;
      margin-right: 20px;
      .member {
        position: absolute;
        width: 120px;
        right: -25px;
        top: -45px;
      }
      .ant-col {
        color: rgba(0, 0, 0, 0.8);
        font-size: 14px;
        margin-bottom: 20px;
        font-weight: 700;
        span {
          font-weight: 400;
          font-size: 14px;
          color: black;
          display: inline-block;
          margin-left: 10px;
        }
      }
      .cardDetails_mechanismInfo_right {
        margin-left: 50px;
        width: 25%;
        .ant-rate {
          color: rgb(217, 0, 27);
          margin-left: 10px;
          font-size: 14px;
        }
        span {
          display: block;
        }
        .text {
          display: inline-block;
        }
        .img {
          display: block;
          margin-bottom: 10px;
          //margin: auto auto 10px;
        }
      }
    }
  }
  footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    background: rgb(183, 28, 58);
    padding: 20px;
    font-size: 14px;
    box-sizing: border-box;
    div {
      display: flex;
    }
    .footer_left {
      display: block;
      margin-left: 20px;
    }
  }
}
