#CorporatePublicity {
  position: relative;
  height: 100%;
  .organ-util {
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    .util-left {
      width: 20%;
      .title {
        border-left: solid #b71c3a 5px;
        font-size: 18px;
        font-weight: 700;
        padding-left: 10px;
      }
    }
    .organ-right {
      width: 60%;
      display: flex;
    }
  }
}
.modalAppraise {
  .ant-form-item-label{
    width: 95px;
  }
}
