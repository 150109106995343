#loginForm {
  margin-top: 50px;
  .form-row {
    display: flex;
  }
  .register-text {
    color: rgb(217, 0, 27);
    cursor: pointer;
  }
  .forget-password {
    text-align: right;
    margin-top: -15px;
    margin-bottom: 15px;
    cursor: pointer;
    &:hover {
      color: rgb(217, 0, 27);
    }
  }
  .ant-form-item-label {
    width: 60px;
    font-weight: 700;
    font-size: 20px;
  }
  .ant-form-item-label >label {
    font-size: 17px;
  }
  .ant-form-item {
    text-align: left;
    margin-bottom: 22px;
  }
  .vertigyCode {
    margin-left: 20px;
    .react-captcha {
      height: 35px;
    }
  }
  .ant-input-affix-wrapper {
    padding: 0 11px 0 11px;
  }
  .ant-input {
    height: 35px;
    border: #fbb4b5 1px solid
  }
  .ant-input-password {
    border: #fbb4b5 1px solid;
    .ant-input {
      border: none
    }
  }
  .ant-btn {
    width: 40%;
    height: 40px;
    font-size: 20px;
  }
}
