#personManagement {
  position: relative;
  height: calc(100% - 40px);
  .organ-head {
    box-shadow: 0px 3px 10px 0px #e6e9f0;
    border-radius: 4px;
    background-color: #fff;
    margin-bottom: 20px;
  }
  .add_personnel {
    color: #3079ea;
    border: 1px solid #3079ea;
  }
  .organ-contain {

  }
  .organ-util {
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    .line-util {
      margin: 0 30px;
      color: #d9d9d9;
      font-size: 15px;
    }
    .excel-btn {
      color: #93A0B0!important;
      &:hover {
        color: #3079ea!important;
      }
    }
    .dis-print {
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      background: #f5f5f5;
      text-shadow: none;
      box-shadow: none;
      &::after {
        display: none;
      }
    }
  }

}

.stopModal {
  padding: 20px 0;
  .warn-text-first {
    .warn-icon {
      color: #fab833;
    }
    font-size: 14px;
    font-weight: 700;
    color: #333333;
    letter-spacing: 1px;
  }
  .warn-text-sec {
    margin-top: 13px;
    margin-bottom: 27px;
    font-size: 14px;
    color: #666666;
    letter-spacing: 1px;
  }
  .warn-footer {
    text-align: center;
  }
}
.printModal {

  .print-content {
    height: 480px;
    overflow: hidden;
    text-align: center;
    .row-fir {
      font-size: 18px;
      color: #333333;
      letter-spacing: 1px;
      margin-bottom: 18px;
    }
    .row-img {
      margin-bottom: 18px;
    }
  }
}
.disCloseModal {
  .ant-modal-close {
    cursor: not-allowed;
  }
}
