#PersonnelDetailsH5 {
  background: rgb(242, 242, 242);

  header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1em;
    font-weight: 700;
    color: white;
    height: 40px;
    background: rgb(217, 0, 27);
  }

  main {
    padding: 10px;
    box-sizing: border-box;

    .cardDetails {
      position: relative;
      padding: 15px 15px;
      box-sizing: border-box;
      min-height: 200px;
      margin-top: 10px;
      background: white;
      border-radius: 10px;
      .table {
        height: 40px;
        margin: 0!important;
        border: solid 1px rgb(222, 222, 222);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
      }
      .cardDetails_information_img {
        img {
          width: 100%;
        }
      }
      .cardDetails_information {
        h4 {
          display: flex;
          align-items: center;
        }
        .abnormal,.normal {
          margin-left: 20px;
          padding: 2px;
          padding-right: 10px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          font-size: 13px;
          font-weight: 400;
          img {
            margin-right: 5px;
            width: 15px;
          }
        }
        .normal {
          color: #d9001b;
          background: #f2aab3;
        }
        .abnormal {
          color: #1fb19e;
          background: #a5e0d8;
        }
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 20px;
        font-size: 0.9rem;
        div {
          display: flex;
          align-items: center;
        }

        .ant-rate {
          font-size: 0.9em;
          margin-left: 5px;
          color: rgb(217, 0, 27);
        }
      }
      .cardDetails_title {
        margin-top: 0;
        margin-bottom: 20px;
        border-left: solid 5px rgb(217, 0, 27);
        display: block;
        padding-left: 10px;
      }
      .certificates_img {
        img {
          width: 98%;
          height: 130px;
          display: block;
          margin: auto;
        }
        span {
          display: block;
          margin-top: 5px;
          text-align: center;
        }
      }
      .certificates {
        width: 100%;
        height: 50px;
        background: rgb(31, 177, 158);
        border-radius: 5px;
        font-size: 0.8em;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        box-sizing: border-box;
        padding-right: 5px;
        padding-left: 5px;
        span {
          color: white;
          display: inline-block;
        }
        img {
          width: 32px;
          height: 32px;
        }
        .text {
          color: white;
          margin: 0;
          width: 100px;
        }
        .text_date {
          width: 134px;
        }
        .text_click {
          width: 70px;
        }
      }
      .mechanism_title {
        display: flex;
        align-items: center;
        padding-left: 10px;
        img {
          width: 21px;
        }
        h4 {
          margin-left: 5px;
        }
      }
      .mechanism {
        padding-left: 10px;
        margin-top: 10px;
        .ant-col {
          color: rgba(0, 0, 0,0.8);
          font-weight: 700;
          span {
            font-weight: 400;
            color: rgb(0,0,0);
            display: inline;
            margin-left: 10px;
          }
          margin-bottom: 15px;
          font-size: 0.9rem;
        }
      }
    }
  }

  footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    background: rgb(183, 28, 58);
    padding: 10px 0;
    font-size: 0.8rem;
    box-sizing: border-box;

    div {
      display: flex;
    }

    .footer_left {
      display: block;
      margin-left: 20px;
    }
  }
}

h5 {
  display: inline-block;
  margin: 0 0 0 5px;
}

h4 {
  margin: 0;
  display: inline-block;
}
.grey {
  background:rgb(242, 242, 242);
}
.white {
  background:rgb(255, 255, 255);
}
.borderLeft {
  border-left: none !important;
}
.borderBottom {
  border-bottom: none !important;
}
