#setPassword {
  box-sizing: border-box;
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title1 {
    font-size: 30px;
    color: #333333;
    letter-spacing: 2px;
    margin-bottom: 12px;
  }
  .title2 {
    font-size: 14px;
    color: #666666;
    letter-spacing: 1px;
    margin-bottom: 40px;
  }
}