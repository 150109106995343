#printTemplate {
  -webkit-user-select: none;  /* Safari */
  -moz-user-select: none;     /* Firefox */
  -ms-user-select: none;      /* IE/Edge */
  user-select: none;          /* 标准语法 */
  pointer-events: none;       /* 阻止点击事件 */
  margin: 20px auto auto;
  width: 400px;
  height: 600px;
  background-size: 100% 100%;
  position: relative;
  .img-box {
    position: absolute;
    top: 130px;
    left: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      margin-bottom: 5px;
      width: 65px;
    }
  }
  .avatar_head {
    width: 120px;
    height: 160px;
    position: absolute;
    top: 126px;
    left: 135px;
  }
  .information {
    position: absolute;
    top: 310px;
    left: 167px;
    //width: 400px;
    font-size: 17px;
    div {
      width: 170px;
      height: 25px;
      font-weight: 700;
      text-align: left;
      color: rgb(54, 55, 55);
      img {
        margin: 0;
        width: 165px;
        height: 25px;
      }
    }
  }
}
.associate {
  background-image: url('../../../assest/image/associate.png');
}
.agent {
  background-image: url('../../../assest/image/agent.png');
}
.business {
  background-image: url('../../../assest/image/business.png');
}
.develop {
  background-image: url('../../../assest/image/develop.png');
}
.watermark {
  width: 100%;
  height: 600px;
  position: absolute;
  z-index: 99;
}
