#organManagement {
  position: relative;
  height: calc(100%);
  background-color: #fff;
  .organ-util {
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    .excel-btn {
      color: #93A0B0!important;
      &:hover {
        color: #3079ea!important;
      }
    }
    .line-util {
      margin: 0 30px;
      color: #d9d9d9;
      font-size: 15px;

    }
  }
}
.current_0 {
}
.isReceiveWay {
  padding: 0px 50px 0;
  box-sizing: border-box;
  span {
    display: block;
    margin-top: 10px;
  }
}
.cardMaking {
  .ant-modal-close {
    display: block !important;
  }
}

