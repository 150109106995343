.person-table {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  .ant-table-thead .person-action {
    padding-left: 30px;
  }
  .ant-btn {
    padding: 4px 5px;
  }
  .ant-table-pagination.ant-pagination {
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .ant-table-thead > tr > th {
    font-weight: 700;
  }
  .ant-table-body {
    height: 565px;
  }
  .circle-prop {
    display: flex;
    align-items: center;
  }
  .circle {
    width: 6px;
    height: 6px;
    opacity: 1;
    background: #cccccc;
    border-radius: 50%;
    margin-right: 5px;
  }
  .circle_red,.circle-prop_red {
    color: #FF4D4F;
  }
  .circle_red {
    background-color: #FF4D4F;
  }
  .circle-prop_green,.circle_green {
    color: #10A200;
  }
  .circle_green {
    background-color: #10A200;
  }
}
.person-table .ant-table-body {
  height: 645px;
}
