#AddPersonnelRegister {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  box-sizing: border-box;
  //padding: 25px 60px;
  overflow: auto;
  .form {
    padding: 20px 60px;
    width: auto;
    .ant-select,.ant-picker {
      width: 100%!important;
    }
    .ant-form-item-label {
      width: 160px;
    }
    .title {
      border-left: solid #b71c3a 5px;
      font-size: 18px;
      font-weight: 700;
      padding-left: 10px;
    }
    .review {
      margin-top: 25%;
      display: flex;
      .review-title {
        font-size: 18px;
        margin-left: 20px;
        font-weight: 700;
        margin-bottom: 30px;
      }
      .review-content {
        margin-left: 20px;
        margin-bottom: 10px;
        .yellow-text {
          color: rgb(245, 154, 35);
        }
      }
    }
    .review-back {
      margin-top: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .audit-doing {
    width: 628px;
    min-height: 44px;
    box-sizing: border-box;
    padding-left: 20px;
    border:  1px solid #faad14;
    color: #faad14;
    line-height: 44px;
    font-size: 14px;
    background-color: #fffbe6;
    margin-bottom: 20px;
  }
  .audit-error {
    width: 628px;
    min-height: 44px;
    box-sizing: border-box;
    padding-left: 20px;
    border:  1px solid #ff4d4f;
    color: #ff4d4f;
    line-height: 44px;
    font-size: 14px;
    background-color: #fff2f0;
    margin-bottom: 20px;
  }
  .icon-warn {
    margin-right: 10px;
  }
}
.LoginPersonRegister {
  padding: 0px !important;
  .addPersonelTop {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 99;
    .left-back {
      padding-top: 15px;
      padding-left: 15px;
      background: rgb(242, 242, 242);
      img {
        cursor: pointer;
      }
    }
    .site-navigation-steps {
      background: rgb(242, 242, 242) !important;
    }
  }
}

.detailPersonnelRegister {
  .ant-input-affix-wrapper,.ant-select-selector,.ant-input,.ant-cascader-input {
    border: none !important;
  }
  .dividing {
    height: 15px;
    background: rgb(242, 242, 248);
  }
  .title {
    border-left: solid #b71c3a 5px;
    font-size: 18px;
    font-weight: 700;
    padding-left: 10px;
  }
  .auditTrails {
    padding: 20px 60px;
    .state {
      display: block;
      margin: 20px;
      font-weight: 700;
      font-size: 14px;
      span {
        font-weight: 400;
      }
    }
    .recording {
      padding-left: 20px;
      li {
        //color: red;
        display: flex;
        align-items: center;
        list-style-type:none;
        margin-top: 10px;
        div {
          width: 10px;
          height: 10px;
          background: #b71c3a;
          border-radius: 50%;
          margin-right: 10px;
        }
        .success {
          background: rgb(75, 121, 0);
        }
        span{
          color: black;
          font-size: 14px;
        }
      }
    }
  }
  .onBack {
    margin: auto;
    display: block;
  }
  .info {
    padding: 20px 60px;
    .row {
      margin-top: 20px;
      margin-bottom: 20px;
      .label {
        width: 130px;
      }
      .ant-col {
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: 700;
        display: flex;
      }
    }
  }
}
