#loginTab {
  margin-bottom: 33px;
  .ant-tabs-nav-list {
    width: 100%;
  }
  .ant-tabs-tab {
    flex-grow: 1;
    justify-content:center;
  }
}
.login-fire-tab {
  .ant-tabs-nav-operations {
    display: none !important;
  }
}