#appraise {
  .navigation {
    padding: 10px;
    border-bottom: solid 1px rgb(215, 215, 215);
    display: flex;
    justify-content: center;
  }
  .state {
    width: 100%;
    text-align: center;
    div {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgb(244, 244, 244);
      margin: 10px;
      font-weight: 700;
      font-size: 13px;
      color: rgb(219, 0, 27);
    }
  }
  .rowTable {
    margin: 10px;
    border: solid rgb(215, 215, 215) 1px;
    .text_right {
      justify-content:right ;
    }
    .border_bottom {
      border-bottom: solid rgb(215, 215, 215) 1px;
    }
    .border_right {
      border-right: solid rgb(215, 215, 215) 1px;
    }
    .background_color {
      background-color: rgb(242, 242, 242);
    }
    .col {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding: 5px;
      height: 40px;
    }
  }
  .title {
    margin: 10px;
  }
  .btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
  }
}
#userReview {
  .navigation {
    padding: 10px;
    border-bottom: solid 1px rgb(215, 215, 215);
    display: flex;
    justify-content: space-between;
  }
  .state {
    width: 100%;
    text-align: center;
    div {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgb(244, 244, 244);
      margin: 10px;
      font-weight: 700;
      color: rgb(219, 0, 27);
    }
  }
  .ant-form-item-label {
    width: 100px;
    max-width: none;
    flex: none;
  }
  .ant-form-item-control {
    width: 100%;
    max-width: none;
    flex: 1 1 0;
  }
  :where(.css-dev-only-do-not-override-1o3resx).ant-row {
    flex-flow: nowrap;
  }
  .btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
  }
}
