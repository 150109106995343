#InstitutionalDetailsH5 {
  background: rgb(242, 242, 242);
  header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    font-weight: 700;
    color: white;
    height: 40px;
    background: rgb(217, 0, 27);
  }
  main {
    margin: auto;
    padding: 10px;
    box-sizing: border-box;
    .cardDetails {
      position: relative;
      .member {
        position: absolute;
        width: 80px;
        right: 10px;
      }
      .abnormal,.normal {
        margin-left: 20px;
        padding: 2px;
        padding-right: 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: 400;
        img {
          margin-right: 5px;
          width: 15px !important;
        }
      }
      .normal {
        color: #d9001b;
        background: #f2aab3;
      }
      .abnormal {
        color: #1fb19e;
        background: #a5e0d8;
      }
      .table {
        height: 40px;
        margin: 0!important;
        border: solid 1px rgb(222, 222, 222);
        font-size: 0.8rem !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      width: 100%;
      background: rgb(255, 255, 255);
      border-radius: 10px;
      padding: 10px;
      box-sizing: border-box;
      .ant-col {
        margin-bottom: 15px;
        font-size: 0.9rem;
        color: rgba(0, 0, 0,0.8);
        font-weight: 700;
        span {
          margin-left: 10px;
          font-weight: 400;
        }
        img {
          width: 98%;
        }
        .ant-rate {
          color: rgb(217, 0, 27);
          font-size: 0.9rem;
        }
      }
      .title {
        padding-left: 5px;
        border-left: 5px solid rgb(217, 0, 27);
        margin: 0 0 8px;
      }
    }
  }
  footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    background: rgb(183, 28, 58);
    padding: 10px 0;
    font-size: 0.8rem;
    box-sizing: border-box;
    div {
      display: flex;
    }
    .footer_left {
      display: block;
      margin-left: 20px;
    }
  }
}
.grey {
  background:rgb(242, 242, 242);
}
.white {
  background:rgb(255, 255, 255);
}
.borderLeft {
  font-weight: 400 !important;
  font-size: 12px !important;
  border-left: none !important;
}
.borderBottom {
  border-bottom: none !important;
}
