.organ-table {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  .organManagement-row:hover {
    // background-color: #eaf1fd;
  }
  .ant-table-thead .organ-action {
    padding-left: 30px;
  }
  .ant-table-thead > tr > th {
    font-weight: 700;
  }
  .ant-table-pagination.ant-pagination {
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .ant-table-body {
    height: 565px;
  }
  .circle-prop {
    display: flex;
    align-items: center;
  }
  .circle {
    width: 6px;
    height: 6px;
    opacity: 1;
    background: #cccccc;
    border-radius: 50%;
    margin-right: 5px;
  }
  .circle_red,.circle-prop_red {
    color: #FF4D4F;
  }
  .circle_red {
    background-color: #FF4D4F;
  }
}
#behavior,#Publicity {
  .util {
    padding: 20px 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      width: 40%;
      .ant-tabs-top >.ant-tabs-nav::before {
        border: none;
      }
      .ant-tabs-tab {
        font-weight: 700;
      }
    }
    .right {
      width: 50%;
      display: flex;
      align-items: center;
    }
  }
}
#BlackList,#BadBehavior,#goodBehavior {
  position: relative;
  height: calc(100%);
  background-color: #fff;
  .title {
    border-left: solid #b71c3a 5px;
    font-size: 18px;
    font-weight: 700;
    padding-left: 10px;
  }
  .description {
    font-size: 16px;
    text-indent:32px;
    line-height: 25px;
    padding-top: 10px;
    padding-bottom: 0;
    margin: 0;
  }
  .organ-util {
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    .excel-btn {
      color: #93A0B0!important;
      &:hover {
        color: #3079ea!important;
      }
    }
    .line-util {
      margin: 0 30px;
      color: #d9d9d9;
      font-size: 15px;

    }
  }

}
