#addOrgan {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  box-sizing: border-box;
  padding: 25px 60px;
  overflow: auto;
  .title {
    border-left: solid #b71c3a 5px;
    font-size: 18px;
    font-weight: 700;
    padding-left: 10px;
    margin-bottom: 20px;
  }
  .add-organ-form {
    width: 900px;
  }
  .ant-input[disabled],.ant-cascader-picker-disabled{
    color: #333333!important;
    background-color: #f5f4f9!important;
  }
  .ant-radio-disabled + span {
    color: #333333;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #333333!important;
    background-color: #F5F4F9;
  }
  .ant-input-affix-wrapper-disabled {
    background-color: #F5F4F9;
  }
  .ant-radio-disabled .ant-radio-inner{
    background-color:#F5F4F9;
  }
  .form-title {
    font-size: 16px;
    font-weight: 700;
    color: #333333;
    letter-spacing: 2px;
    margin-bottom: 30px;
  }
  .basic-info {

    display: flex;
    justify-content: space-between;
  }
  .form-row-dynamic {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .organ-upload {
      display: flex;
      width: 402px;
      flex-direction: column;
      .ant-upload.ant-upload-select-picture-card {
        width: 80px;
        height: 80px;
        opacity: 1;
        background: #ffffff;
        border: 1px dashed #cccccc;
      }
      .ant-upload-list-picture-card-container {
        width: 80px;
        height: 80px;

      }
      .ant-upload-list-picture-card .ant-upload-list-item {
        padding:2px;
      }
      .upload-tip {
        color: #999999;
        font-size: 12px;
      }
  }
  .icon-warn,.icon-error {
    margin-right: 10px;
  }
  .ant-form-item-label {
    width: 160px;
    overflow: visible;
  }
  .inp_width,.agency-radio,.ant-cascader-picker {
    width: 300px;
  }
  // 130px
  .form-row {
    display: flex;
    margin: auto;
    .form-merge {
      // display: flex;
    }
  }
  .form-offest {
    margin-right: 100px;
  }
  .ant-select {
    width: 100%!important;
  }
  .audit-doing {
    width: 628px;
    min-height: 44px;
    box-sizing: border-box;
    padding-left: 20px;
    border:  1px solid #faad14;
    color: #faad14;
    line-height: 44px;
    font-size: 14px;
    background-color: #fffbe6;
    margin-bottom: 20px;
    letter-spacing:1px;
  }
  .audit-error {
    width: 628px;
    min-height: 44px;
    box-sizing: border-box;
    padding-left: 20px;
    border:  1px solid #ff4d4f;
    color: #ff4d4f;
    line-height: 44px;
    font-size: 14px;
    background-color: #fff2f0;
    margin-bottom: 20px;
    letter-spacing:1px;
  }
}

.LoginOrganRegister {
  padding: 15px !important;
  .person-res-tit {
    font-size: 28px;
    font-weight: 700;
    color: #333333;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
  }
  .add-organ-form {
    width: 875px!important;
  }
  .left-back:hover {
    cursor: pointer;
  }
  .add-organ-form .form-offest {
    //margin-right: 70px!important;
  }
  .add-organ-form .form-spe .form-offest {
    margin-right: 0!important;
  }
}
.detailOrgan {
  .ant-tabs-tab-btn {
    font-weight: 700;
  }
  .ant-input-affix-wrapper,.ant-select-selector,.ant-input,.ant-cascader-input {
    border: none !important;
  }
  .title {
    border-left: solid #b71c3a 5px;
    font-size: 18px;
    font-weight: 700;
    padding-left: 10px;
  }
  .info {
    display: flex;
    //margin-top: 20px;
    .info_left {
      width: 25%;
      box-sizing: border-box;
      padding: 20px;
      img {
        width: 100px;
        height: 135px;
      }
    }
    .info_right {
      width: 75%;
      .row {
        margin-bottom: 20px;
        position: relative;
        .row_member {
          position: absolute;
          left: 29%;
          bottom: -30px;
          width: 100px;
          pointer-events: none;
        }
        .ant-col {
          margin-top: 20px;
          font-weight: 700;
          font-size: 14px;
          .label {
            width: 100px;
            display: inline-block;
            text-align: right;
          }
        }
      }
    }
    .state {
      display: block;
      margin-top: 20px;
      margin-left: 20px;
      font-weight: 700;
      font-size: 14px;
      span {
        font-weight: 400;
      }
    }
    .recording {
      padding-left: 20px;
      li {
        //color: red;
        display: flex;
        align-items: center;
        list-style-type:none;
        margin-top: 10px;
        div {
          width: 10px;
          height: 10px;
          background: #b71c3a;
          border-radius: 50%;
          margin-right: 10px;
        }
        .success {
          background: rgb(75, 121, 0);
        }
        span{
          color: black;
          font-size: 14px;
        }
      }
    }
  }
}
.imgCrop {
  .reactEasyCrop_CropArea {
    //color: rgba(56, 36, 116,0.8);
  }
  .reactEasyCrop_CropArea{
    //position: relative;
  }
  .reactEasyCrop_CropArea::after {
    background-image: url("../../../assest/image/xbx.png");
    content: "";
    background-repeat:no-repeat;
    background-size:100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
