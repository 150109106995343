.imgList {
  width: 100%;
  height: 40px;
  margin-left: 10px;
  span {
    height: 40px;
  }
}
.col_brand {
  margin: 5px;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.31);
  display: flex;
  justify-content: center;
  align-items: center;
}
.row_brand {
  height: 500px;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
