#login-layout {
  height: 100vh;
  // background: #2e75d5;
  // padding: 400px;
  background-size:100% 100%;
  background-image: url('../../assest/image/layout_bg.jpg');
  box-sizing: border-box;
  position: relative;
  .download {
    position: absolute;
    top: 1%;
    left: 88%;
    width: 12%;
    text-align: center;
    font-size: 18px;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
  }
  .foot {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 750px;
    text-align: center;
    margin-left: -350px;
    span {
      display: block;
      margin-bottom: 10px;
      //font-weight: 700;
    }
  }
  .login-container {
    height:100%;
    display: flex;
    box-sizing: border-box;
    padding: 2%;
    .login-left {
      width: 60%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .title {
        width: 622px;
        height: 360px;
        font-size: 50px;
        .introduce {
          font-size: 30px;
          margin-top: 50px;
          margin-left: 100px;
          color: #b71c3a;
        }
      }
    }
    .login-right {
      position: relative;
      overflow: auto;
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
      .login-form-con {
        width: 622px;
        height: 360px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size:100% 100%;
        box-sizing: border-box;
        background-image: url("../../assest/image/login_bg.png");
      }
    }
  }
}
.businessLicense {
  position: relative;
  .remark-message {
    position: absolute;
    left: -130px;
    top: 30px;
  }
}
